<template>
  <a-list
    itemLayout="vertical"
    :pagination="pagination"
    :data-source="list"
    :grid="{ gutter: 8, xs: 2, sm: 2, md: 2, lg: 3, xl: 4, xxl: 4 }"
  >
    <a-list-item
      slot="renderItem"
      key="item.title"
      slot-scope="item, index"
      @click="handleAeestDetails(item)"
    >
      <a-row class="list-item">
        <div class="list-dept" v-if="item.canApplyToDebt == '1'">
          <img src="../../assets/img/dept.png" alt="" />
        </div>
        <div class="list-dept-font" v-if="item.canApplyToDebt == '1'">
          <div>可用凭证抵付</div>
        </div>
        <div
          class="list-dept-mask"
          v-if="item.bidStage == '4' || item.bidStage == '5'"
        ></div>
        <div class="list-dept-deal" v-if="item.bidStage == '4'">
          <img
            style="width: 160px; height: 110px"
            src="../../assets/img/deal.png"
            alt=""
          />
        </div>
        <div class="list-dept-pass" v-if="item.bidStage == '5'">
          <img
            style="width: 150px; height: 100px"
            src="../../assets/img/pass.png"
            alt=""
          />
        </div>
        <a-row style="padding: 5px 5px 0 5px; overflow: hidden">
          <!-- <div>i</div> -->
          <img
            v-if="item.cover"
            class="list-img"
            alt="logo"
            :src="
              item.cover
                ? $getFileUrl(item.cover.split(',')[0])
                : '../../assets/img/asset_pic.jpg'
            "
          />
          <img
            v-else
            class="list-img"
            alt="logo"
            src="../../assets/img/asset_pic.jpg"
          />
        </a-row>

        <a-row style="padding: 10px">
          <div style="height: 55px">
            <a @click="handleAeestDetails(item)" class="item-title"
              ><a-tooltip>
                <template slot="title">{{ item.assetName }} </template>
                {{ item.assetName }}
              </a-tooltip></a
            >
          </div>
          <!-- <a-list-item-meta>
            
          </a-list-item-meta> -->
          <div
            v-if="item.reservePrice"
            class="account-book"
            style="color: black"
          >
            <a-icon type="account-book" /><span>
              {{
                item.bidStage == "1" || item.bidStage == "2"
                  ? "起拍价"
                  : item.bidStage == "3"
                  ? "当前价"
                  : item.bidStage == "4"
                  ? "成交价"
                  : item.bidStage == "5"
                  ? "流拍价"
                  : ""
              }}
            </span>
            <span
              class="price"
              v-if="item.bidStage == '3' || item.bidStage == '4'"
              >{{ item.price }}</span
            >
            <span v-else class="price">{{ item.reservePrice }}</span> 元
          </div>
          <div style="color: black">
            <a-icon type="history" /> 竞价开始时间：{{ item.biddingStartTime }}
          </div>
          <!-- <div>
            <a-icon type="history" /> 是否可用凭证抵付：{{
              item.canApplyToDebt_dictText
            }}
          </div> -->
        </a-row>
        <a-row class="list-buttom">
          <a-col class="list-buttom-red" :span="12"
            ><div
              style="display: flex; justify-content: center"
              v-if="item.bidStage == '1' || item.bidStage == '2'"
            >
              <a-statistic-countdown
                :valueStyle="{
                  fontSize: '14px',
                  color: '#000',
                  lineHeight: '30px',
                }"
                :value="new Date(item.registrationEndTime).getTime()"
                format="D 天 H 时 m 分 s 秒"
              />
            </div>
            <div
              style="display: flex; justify-content: center"
              v-else-if="item.bidStage == '3'"
            >
              <a-statistic-countdown
                :valueStyle="{
                  fontSize: '14px',
                  color: '#000',
                  lineHeight: '30px',
                }"
                :value="new Date(item.biddingEndTime).getTime()"
                format="D 天 H 时 m 分 s 秒"
              />
            </div>
            <div v-else-if="item.bidStage == '6'">
              <a-statistic-countdown
                :valueStyle="{
                  fontSize: '14px',
                  color: '#000',
                  lineHeight: '30px',
                }"
                :value="new Date(item.biddingStartTime).getTime()"
                format="D 天 H 时 m 分 s 秒"
              />
            </div>
            <div v-else-if="item.bidStage == '7'">暂停</div>
            <div v-else-if="item.bidStage == '8'">撤拍</div>
            <div v-else>已结束</div></a-col
          >
          <a-col class="list-buttom-gray" :span="12">
            {{ item.bidStage_dictText }}
          </a-col>
        </a-row>
      </a-row>
      <!-- <a-row class="list-item">
        <a-row>
          <img
            v-if="item.cover"
            style="height: 200px; width: 100%"
            alt="logo"
            :src="
              item.cover
                ? item.cover.split(',')[0]
                : '../../assets/img/asset_pic.jpg'
            "
          />
          <img
            v-else
            style="height: 200px; width: 100%"
            alt="logo"
            src="../../assets/img/asset_pic.jpg"
          />
        </a-row>

        <a-row style="padding: 10px">
          <a-list-item-meta>
            <a @click="handleAeestDetails(item)" slot="title" class="item-title"
              ><a-tooltip>
                <template slot="title">{{ item.assetName }} </template
                >{{
                  item.assetName.length > 14
                    ? item.assetName.substr(0, 14) + "..."
                    : item.assetName
                }}
              </a-tooltip></a
            >
          </a-list-item-meta>
          <div v-if="item.reservePrice" class="account-book">
            <a-icon type="account-book" /><span>
              {{
                item.bidStage == "1" || item.bidStage == "2"
                  ? "起拍价"
                  : item.bidStage == "3"
                  ? "当前价"
                  : item.bidStage == "4"
                  ? "成交价"
                  : item.bidStage == "5"
                  ? "流拍价"
                  : ""
              }}
            </span>
            <span class="price">{{ item.reservePrice }}</span> 元
          </div>
          <div>
            <a-icon type="history" /> 竞价开始时间：{{ item.biddingStartTime }}
          </div>
          <div>
            <a-icon type="history" /> 是否可用凭证抵付：{{
              item.canApplyToDebt_dictText
            }}
          </div>
          <a-row class="list-title">
            <a-col :span="16" style="margin-top: 10px">
              <span
                ><a-icon style="color: red" type="fire" />
                {{ item.pageViewCount }}次围观</span
              >
            </a-col>
            <a-col :span="8">
              <a-button
                v-if="
                  item.bidStage == '1' ||
                  item.bidStage == '2' ||
                  item.bidStage == '3'
                "
                @click="handleAeestDetails(item)"
                type="danger"
                >{{ item.bidStage_dictText }}<a-icon type="right"
              /></a-button>
              <a-button
                v-if="item.bidStage == '4'"
                @click="handleAeestDetails(item)"
                type="primary"
                >{{ item.bidStage_dictText }}<a-icon type="right"
              /></a-button>
              <a-button
                v-if="item.bidStage == '5'"
                @click="handleAeestDetails(item)"
                >{{ item.bidStage_dictText }}<a-icon type="right"
              /></a-button>
            </a-col>
          </a-row>
        </a-row>
      </a-row> -->
    </a-list-item>
  </a-list>
</template>

<script>
import router from "@/router";

export default {
  name: "AuctionList",
  props: ["list", "total", "current"],
  data() {
    return {
      bidStageList: [
        { key: 1, value: "公告中", type: "primary" },
        { key: 2, value: "报名中", type: "danger" },
        { key: 3, value: "竞价中", type: "danger" },
        { key: 4, value: "已成交", type: "primary" },
        { key: 5, value: "流拍", type: "primary" },
      ],
      pagination: {
        // onChange: (page) => this.$parent.getNewsList(page),
        // current: 1, // 当前页数
        // total: 0,
        // pageSize: 4,
      },
    };
  },
  beforeUpdate() {
    this.pagination = {
      onChange: (page) => this.$emit("getAssetsList", page),
      current: this.current, // 当前页数
      total: this.total,
      pageSize: 8,
    };
  },

  methods: {
    handleAeestDetails(record) {
      router.push({
        path: "/assetDetail",
        query: {
          assetNumber: record.assetNumber,
          anouncementId: record.anouncementId,
        },
      });
      //  router.push({ path: "/operate", query: { id: record.id } });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-list-item-meta {
  margin-bottom: 0px !important;
}
// /deep/.ant-list-item-meta-title {
//   margin-bottom: 0px !important;
// }
.account-book {
  font-size: 16px;
  .price {
    color: red;
    font-size: 19px;
  }
}
.list-item {
  position: relative;
  margin-left: 10px;
  background-color: #fff;
  // border: 1px #eaedf2 solid;
  border: none;
  border-radius: 15px;
  color: #fff;
  height: 360px;
  .list-dept {
    z-index: 2;
    position: absolute;
    top: 5px;
    left: -12px;
  }
  .list-dept-font {
    font-size: 16px;
    z-index: 3;
    position: absolute;
    top: 20px;
    left: 17px;
  }
  .list-dept-deal {
    z-index: 3;
    position: absolute;
    top: 100px;
    left: 80px;
  }
  .list-dept-pass {
    z-index: 3;
    position: absolute;
    top: 100px;
    left: 80px;
  }
  .list-dept-mask {
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色遮罩 */
    z-index: 1; /* 确保遮罩层在上面 */
  }
  .list-img {
    // margin: 5px 5px 0px 5px;
    transition: transform 0.5s ease;
    border-radius: 15px;
    height: 200px;
    width: 100%;
  }
  .list-img:hover {
    -ms-transform: scale(1.2); /* IE 9 */

    -webkit-transform: scale(1.2); /* Safari 3-8 */

    transform: scale(1.2); /* 放大到1.5倍 */
  }
  .list-item-content {
    color: #000;
  }
  .list-buttom {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    bottom: 0;
    .list-buttom-red {
      height: 30px;
      border-bottom-left-radius: 15px;
      color: #000;
      background-color: #e7e7e7;
    }
    .list-buttom-gray {
      font-weight: 700;
      height: 30px;
      border-bottom-right-radius: 15px;
      background-color: #3d3d3d;
    }
  }
}
.item-title {
  color: #000;
  font-weight: bold;
  font-size: 17px;
}
.list-item:hover {
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2),
    /* 底部阴影 */ 0 5px 5px rgba(0, 0, 0, 0.2),
    /* 左侧阴影 */ -5px 0 5px rgba(0, 0, 0, 0.2),
    /* 右侧阴影 */ 5px 0 5px rgba(0, 0, 0, 0.2);
  /* background-color: rgb(188, 228, 235); */
}
</style>
